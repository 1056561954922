<template>
  <a-modal class="taskModal" width="1048px" v-model="visible" :body-style="{padding: 0}" :footer="false"
           @cancel="() => {
             $emit('reload')
             this.hasDetail = false
           }">
    <template #title>
      <div class="modal-title">
        <img :src="$toUrl('task_blue.png')" alt="">
        #{{ detail.seqid }}
      </div>
      <slot name="IAccept" />
      <slot name="close" />
    </template>
    <div class="modal-body" v-if="hasDetail">
      <div class="body-top">
        <div class="title">
          <edp-input
              v-if="hasEditP"
              v-model="detail.issue_name"
              :db="dbs.prjIssue"
              field-name="issue_name"
              :oid="detail.oid"
              :prj-oid="detail.prjoid"
              @change="$emit('reload')"
              size="big"
          >
          </edp-input>
          <span style="font-size: 18px;font-weight: 500;color: #333;" v-else>{{ detail.issue_name }}</span>
        </div>
        <div class="detail">
          <div class="detail-item">
            <img :src="detail.owners[0].pic" v-if="detail.owners?.[0]?.pic" class="round" alt="">
            <span v-else class="round"></span>
            <div class="right">
              <edp-select
                  v-if="hasEditP"
                  style="width: 100%;"
                  size="mini"
                  v-model="detail.issue_owner"
                  :db="dbs.prjIssue"
                  field-name="issue_owner"
                  :oid="detail.oid"
                  :prj-oid="detail.prjoid"
                  @change="getDetail()"
                  account="prj"
              />
              <template v-else>{{ detail.owners?.[0]?.name }}</template>
              <div class="label">负责人</div>
            </div>
          </div>
          <div class="detail-item">
            <img class="round" v-if="detail.issue_status === '0'" :src="$toUrl('task_status_blue.png')" alt="">
            <img class="round" v-else-if="detail.issue_status === '1'" :src="$toUrl('task_status_yellow.png')" alt="">
            <img class="round" v-else-if="detail.issue_status === '10'" :src="$toUrl('task_suspend.png')" alt="">
            <img class="round" v-else-if="detail.issue_status === '100'" :src="$toUrl('task_status_green.png')" alt="">
            <div class="right">
              <edp-select
                  v-if="hasEditP"
                  style="width: 100%;"
                  size="mini"
                  v-model="detail.issue_status"
                  :db="dbs.prjIssue"
                  field-name="issue_status"
                  :oid="detail.oid"
                  :prj-oid="detail.prjoid"
                  options-type="cat"
                  :options="cat.issue_bstatus"
              ></edp-select>
              <template v-else>{{ getCatNameByCode(detail.issue_status, 'issue_bstatus') }}</template>
              <div class="label">当前状态</div>
            </div>
          </div>
          <div class="detail-item">
            <img :src="$toUrl('level.png')" class="round" alt="">
            <div class="right">
              <edp-select
                  v-if="hasEditP"
                  size="mini"
                  style="width: 100%;"
                  v-model="detail.issue_level"
                  :db="dbs.prjIssue"
                  field-name="issue_level"
                  :prj-oid="detail.prjoid"
                  :oid="detail.oid"
                  options-type="cat"
                  :options="cat.issues_level"
              ></edp-select>
              <template v-else>
                {{ getCatNameByCode(detail.issue_level, 'issues_level') }}
              </template>
              <div class="label">优先级</div>
            </div>
          </div>
          <div class="detail-item">
            <img :src="$toUrl('tophase.png')" class="round" alt="">
            <div class="right">
              <edp-select
                  v-if="hasEditP"
                  style="width: 100%;"
                  size="mini"
                  v-model="detail.phase_oid"
                  :db="dbs.prjIssue"
                  field-name="phase_oid"
                  :oid="detail.oid"
                  :prj-oid="detail.prjoid"
                  :options="stageList"
              ></edp-select>
              <template v-else>{{ detail.phase_name }}</template>
              <div class="label">所属阶段</div>
            </div>
          </div>
          <slot name="detail" />
        </div>
      </div>
      <div class="body-content">
        <div class="left left-tabs">
          <a-tabs v-model="leftTab">
            <a-tab-pane key="1" tab="详情"></a-tab-pane>
            <a-tab-pane key="2" tab="子任务项"></a-tab-pane>
            <a-tab-pane key="6" tab="前置任务"></a-tab-pane>
            <a-tab-pane key="7" tab="后置任务"></a-tab-pane>
            <a-tab-pane key="3" tab="文件"></a-tab-pane>
            <a-tab-pane key="4" tab="财务"></a-tab-pane>
            <a-tab-pane key="5" tab="工时"></a-tab-pane>
          </a-tabs>
          <div class="tab-item active-1" v-show="leftTab === '1'">
            <div class="title">描述</div>
            <div class="form-list" style="grid-template-columns: 1fr">
              <edp-textarea
                  v-if="hasEditP"
                  v-model="detail.issue_desc"
                  :db="dbs.prjIssue"
                  field-name="issue_desc"
                  :prj-oid="detail.prjoid"
                  :oid="detail.oid"
              ></edp-textarea>
              <template v-else>{{ detail.issue_desc }}</template>
            </div>
            <div class="title">属性</div>
            <div class="form-list">
              <div class="form-item" style="grid-column: 1/3;">
                <div class="label">标签</div>
                <div>
                  <edp-checkbox-group
                      v-model="detail.issue_tag"
                      :options="cat.issue_tags.map(item => item.cat_name)"
                      :db="dbs.prjIssue"
                      field-name="issue_tag"
                      :oid="detail.oid"
                      :prj-oid="detail.prjoid"
                  >
                  </edp-checkbox-group>
                </div>
              </div>
              <div class="form-item" style="height: 42px">
                <div class="label">任务编码</div>
                <edp-input
                    style="--width: 100%"
                    v-model="detail.issue_code"
                    :db="dbs.prjIssue"
                    field-name="issue_code"
                    :oid="detail.oid"
                    :prj-oid="detail.prjoid"
                >
                </edp-input>
              </div>
              <div class="form-item" style="height: 42px">
                <div class="label">任务进度(%)</div>
                <edp-input
                    style="--height: 32px;"
                    number
                    :max="100"
                    :precision="0"
                    :db="dbs.prjIssue"
                    field-name="complete_percent"
                    v-model="detail.complete_percent"
                    :oid="detail.oid"
                    :prj-oid="detail.prjoid"
                >
                </edp-input>
              </div>
              <div class="form-item">
                <div class="label">计划开始</div>
                <edp-date-picker
                    v-if="hasEditP"
                    :db="dbs.prjIssue"
                    field-name="schedule_start_date"
                    v-model="detail.schedule_start_date"
                    :oid="detail.oid"
                    :prj-oid="detail.prjoid"
                    @change="changeScheduleStartDate"
                    @openChange="recordStartDate"
                >
                </edp-date-picker>
                <template v-else>{{ detail.schedule_start_date }}</template>
              </div>
              <div class="form-item">
                <div class="label">计划结束</div>
                <edp-date-picker
                    ref="schedule_end_date"
                    v-if="hasEditP"
                    :db="dbs.prjIssue"
                    field-name="schedule_end_date"
                    v-model="detail.schedule_end_date"
                    :oid="detail.oid"
                    :prj-oid="detail.prjoid"
                >
                </edp-date-picker>
                <template v-else>{{ detail.schedule_end_date }}</template>
              </div>
              <div class="form-item">
                <div class="label">实际开始</div>
                <edp-date-picker
                    v-if="hasEditP"
                    :db="dbs.prjIssue"
                    field-name="actual_start_date"
                    v-model="detail.actual_start_date"
                    :oid="detail.oid"
                    :prj-oid="detail.prjoid"
                    @change="getDetail"
                >
                </edp-date-picker>
                <template v-else>{{ detail.actual_start_date }}</template>
              </div>
              <div class="form-item">
                <div class="label">实际结束</div>
                <edp-date-picker
                    v-if="hasEditP"
                    :db="dbs.prjIssue"
                    field-name="actual_end_date"
                    v-model="detail.actual_end_date"
                    :oid="detail.oid"
                    :prj-oid="detail.prjoid"
                    @change="getDetail"
                >
                </edp-date-picker>
                <template v-else>{{ detail.actual_end_date }}</template>
              </div>
              <div class="form-item">
                <div class="label">关联产品</div>
                <edp-select
                    v-if="hasEditP"
                    v-model="detail.product_oid"
                    :db="dbs.prjIssue"
                    field-name="product_oid"
                    :oid="detail.oid"
                    :prj-oid="detail.prjoid"
                    product="prj"
                ></edp-select>
                <template v-else>{{ detail.product_name }}</template>
              </div>
              <div class="form-item">
                <div class="label">协同人</div>
                <edp-select
                    class="synergies"
                    v-if="hasEditP"
                    :db="dbs.prjIssue"
                    :oid="detail.oid"
                    v-model="detail.issue_assist"
                    field-name="issue_assist"
                    :prj-oid="detail.prjoid"
                    account="prj"
                    split-str="/"
                    multiple
                    :maxTagCount="100"
                ></edp-select>
                <template v-else>
                  {{ detail.assists.map(item => item.name).join(',') }}
                </template>
              </div>
            </div>
            <div class="line"></div>
            <div class="title">基础信息</div>
            <div class="form-list">
              <div class="form-item">
                <div class="label">所属项目</div>
                {{ prjDetail.project_name }}
              </div>
              <!--              <div class="form-item">-->
              <!--                <div class="label">成员</div>-->
              <!--                <div v-if="detail.assists">-->
              <!--                  {{ detail.assists.map(item => item.name).join(',') }}-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="form-item">
                <div class="label">创建者</div>
                <div>
                  {{ detail.creator_name }}
                </div>
              </div>
              <div class="form-item">
                <div class="label">创建时间</div>
                <div>
                  {{ detail.create_time }}
                </div>
              </div>
              <div class="form-item">
                <div class="label">更新时间</div>
                <div>
                  {{ detail.update_time }}
                </div>
              </div>
            </div>
          </div>
          <div class="tab-item active-2" v-show="leftTab === '2'">
            <div class="title">
              子任务项
              <div v-if="hasEditP && hasAddTaskP" class="right-action" @click="openCreateTaskModal()">
                <img :src="$toUrl('createTask.png')" alt="">
                新建子任务项
              </div>
            </div>
            <div class="tag-record-list children-list" v-if="children?.length">
              <div class="tag-record-item children-item" v-for="item in children" :key="item.oid"
                   @click="openChildTask(item)">
                <div class="edp-tag" v-if="item?.owners">
                  {{ item.owners.map(i => i?.title ? i?.name + '-' + i?.title : i?.name).join(',') }}
                </div>
                <div class="edp-tag blue" v-if="item.issue_level_name">{{ item.issue_level_name }}</div>
                <div class="edp-tag">阶段：{{ item.phase_name }}</div>
                <a-tooltip>
                  <template slot="title">
                    子任务
                  </template>
                  <img style="width: 20px;height:20px;" :src="$toUrl('renwu.png')" alt="">
                </a-tooltip>
                <div class="name">
                  {{ item.issue_name }}
                </div>
                <div class="edp-status"
                     :class="{
                    blue: item.issue_status === '0',
                    yellow: item.issue_status === '1',
                    green: item.issue_status === '100',
                    gray: item.issue_status === '1000',
                  }">
                  {{ item.issue_status_name }}
                </div>
                <div v-if="hasEditP" class="close" @click.stop="deleteTask(item)">
                  <a-icon type="close" />
                </div>
              </div>
            </div>
            <edp-empty style="margin-top: 30px" v-else></edp-empty>
          </div>
          <div class="tab-item active-6" v-show="leftTab === '6'">
            <div class="title">
              前置任务
              <div class="right-action" @click="openRelevancyWorkModal()">
                <i class="iconfont icon-guanlian hidden" style="margin-right: 5px;"></i>
                设置前置任务项
              </div>
            </div>
            <div class="tag-record-list children-list" v-if="detail.pretask_list?.length">
              <div class="tag-record-item children-item" v-for="item in detail.pretask_list" :key="item.oid"
                   @click="openChildTask(item)">
                <div class="edp-tag" v-if="item.owners">
                  {{ item.owners.map(i => i?.title ? i?.name + '-' + i?.title : i?.name).join(',') }}
                </div>
                <div class="edp-tag blue" v-if="item.issue_level_name">{{ item.issue_level_name }}</div>
                <div class="edp-tag">阶段：{{ item.phase_name }}</div>
                <a-tooltip>
                  <template slot="title">
                    子任务
                  </template>
                  <img style="width: 20px;height:20px;" :src="$toUrl('renwu.png')" alt="">
                </a-tooltip>
                <div>编码：{{ item.issue_code }}</div>
                <div class="name">
                  {{ item.issue_name }}
                </div>
                <div class="edp-status"
                     :class="{
                    blue: item.issue_status === '0',
                    yellow: item.issue_status === '1',
                    green: item.issue_status === '100',
                    gray: item.issue_status === '1000',
                  }">
                  {{ item.issue_status_name }}
                </div>
                <div class="close" @click.stop="deletePreTask(item)">
                  <a-icon type="close" />
                </div>
              </div>
            </div>
            <edp-empty style="margin-top: 30px" v-else></edp-empty>
          </div>
          <div class="tab-item active-7" v-show="leftTab === '7'">
            <div class="title">
              后置任务
            </div>
            <div class="tag-record-list children-list" v-if="detail.todotask_list?.length">
              <div class="tag-record-item children-item" v-for="item in detail.todotask_list" :key="item.oid"
                   @click="openChildTask(item)">
                <div class="edp-tag" v-if="item.owners">
                  {{ item.owners.map(i => i.title ? i.name + '-' + i.title : i.name).join(',') }}
                </div>
                <div class="edp-tag blue" v-if="item.issue_level_name">{{ item.issue_level_name }}</div>
                <div class="edp-tag">阶段：{{ item.phase_name }}</div>
                <a-tooltip>
                  <template slot="title">
                    子任务
                  </template>
                  <img style="width: 20px;height:20px;" :src="$toUrl('renwu.png')" alt="">
                </a-tooltip>
                <div>编码：{{ item.issue_code }}</div>
                <div class="name">
                  {{ item.issue_name }}
                </div>
                <div class="edp-status"
                     :class="{
                    blue: item.issue_status === '0',
                    yellow: item.issue_status === '1',
                    green: item.issue_status === '100',
                    gray: item.issue_status === '1000',
                  }">
                  {{ item.issue_status_name }}
                </div>
              </div>
            </div>
            <edp-empty style="margin-top: 30px" v-else></edp-empty>
          </div>
          <div class="tab-item active-3" v-show="leftTab === '3'">
            <div style="padding: 20px 20px">
              <attachment-upload :disabled="!hasEditP" @change="$refs.bizlog.getBizLogList()" title="文件"
                                 :source-oid="detail.oid" source-type="4" :prj-oid="detail.prjoid"></attachment-upload>
            </div>
          </div>
          <div class="tab-item active-4" v-show="leftTab === '4'">
            <div class="title">财务信息</div>
            <div class="form-list">
              <div class="form-item">
                <div class="label">预计收入总额</div>
                <span style="margin-right: 15px;flex-shrink: 0;">CNY</span>
                <a-input
                    v-if="hasEditP"
                    class="onefield"
                    @blur="$updateOnefield('prj', 'prj_issue', 'schedule_income', detail.schedule_income, detail.oid, detail.prjoid)"
                    v-model.number="detail.schedule_income"></a-input>
                <template v-else>
                  {{ detail.schedule_income }}
                </template>
              </div>
              <div class="form-item">
                <div class="label">实际收入总额</div>
                <span v-if="detail.actual_income" style="margin-right: 15px;flex-shrink: 0;">CNY</span>
                {{ detail.actual_income | numberFormat }}
              </div>
              <div class="form-item">
                <div class="label">预计支出总额</div>
                <span style="margin-right: 15px;flex-shrink: 0;">CNY</span>
                <a-input
                    v-if="hasEditP"
                    class="onefield"
                    @blur="$updateOnefield('prj', 'prj_issue', 'schedule_outcome', detail.schedule_outcome, detail.oid, detail.prjoid)"
                    v-model.number="detail.schedule_outcome"
                ></a-input>
                <template v-else>{{ detail.schedule_outcome }}</template>
              </div>
              <div class="form-item">
                <div class="label">实际支出总额</div>
                <span v-if="detail.actual_outcome" style="margin-right: 15px;flex-shrink: 0;">CNY</span>
                {{ detail.actual_outcome | numberFormat }}
              </div>
            </div>
            <div class="line"></div>
            <div style="padding: 0 0;margin-top: 20px;">
              <issue-money-log :disabled="!hasEditP" ref="moneyLog" @reload="getDetail" :prj-oid="detail.prjoid"
                               :oid="detail.oid"
                               :name="detail.issue_name"></issue-money-log>
            </div>
          </div>
          <div class="tab-item active-4" v-show="leftTab === '5'">
            <div class="title">工时信息</div>
            <div class="form-list">
              <div class="form-item">
                <div class="label">预估工时</div>
                <edp-input
                    style="width: 60px;margin-left: -1px;"
                    v-if="hasEditP"
                    v-model="detail.schedule_worktime"
                    :db="dbs.prjIssue"
                    field-name="schedule_worktime"
                    :oid="detail.oid"
                    :prj-oid="detail.prjoid"
                    @change="getDetail"
                >
                </edp-input>
                <div style="min-width: 60px" v-else>{{ detail.schedule_worktime }}</div>
                <span>小时</span>
              </div>
              <div class="form-item">
                <div class="label">登记工时</div>
                <div style="min-width: 60px">{{ detail.actual_worktime }}</div>
                <div>小时</div>
              </div>
              <div class="form-item">
                <div class="label">剩余工时</div>
                <div style="min-width: 60px">{{ detail.worktime_left }}</div>
                <div>小时</div>
              </div>
              <div class="form-item">
                <div class="label">预估偏差</div>
                <div style="min-width: 60px">{{ detail.worktime_deviation }}</div>
                <div>小时</div>
              </div>
            </div>
            <div class="line"></div>
            <div style="padding: 0 0;">
              <issue-work-time-log ref="workTimeLog" :list="detail.worktimes" :prj-oid="detail.prjoid"
                                   :issue-oid="detail.oid" @reload="getDetail"></issue-work-time-log>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="title">描述</div>
          <div class="tab">
            <a-tabs v-model="rightTab">
              <a-tab-pane key="1" tab="评论"></a-tab-pane>
              <a-tab-pane key="2" tab="动态"></a-tab-pane>
            </a-tabs>
            <div class="y-scroll">
              <div class="active-1" v-show="rightTab === '2'">
                <bizlog ref="bizlog" :source-oid="detail.oid"></bizlog>
              </div>
              <div class="active-2" v-show="rightTab === '1'">
                <comment-list ref="commentList" edit :prjoid="detail.prjoid" :issueoid="detail.oid"></comment-list>
              </div>
            </div>
          </div>
          <div class="comment-input">
            <input type="text" v-model="comment">
            <span class="send" @click="sendComment">发送</span>
          </div>
        </div>
      </div>
    </div>
    <a-skeleton v-else></a-skeleton>
    <relevancy-work-modal ref="relevancyWorkModal" @reload="getDetail" />
    <create-task-modal @reload="getChildren" ref="createTaskModal"></create-task-modal>
    <task-modal ref="taskModal" @reload="getDetail"></task-modal>
  </a-modal>
</template>

<script>
import { fetch } from "@/utils/request.js";
import catMixin from "@/utils/mixins/catMixin.js";
import CreateTaskModal from "@/components/createTaskModal.vue";
import AttachmentUpload from "@/components/attachmentUpload.vue";
import Bizlog from "@/components/bizlog.vue";
import CommentList from "@/components/commentList.vue";
import IssueMoneyLog from "@/components/issueMoneyLog.vue";
import EdpInput from "@/components/e-form/edp-input.vue";
import { FieldUpdateDb } from "@/utils/FieldUpdate.js";
import EdpSelect from "@/components/e-form/edp-select.vue";
import EdpTextarea from "@/components/e-form/edp-textarea.vue";
import EdpDatePicker from "@/components/e-form/edp-date-picker.vue";
import issueWorkTimeLog from "@/components/issueWorkTimeLog.vue";
import EdpCheckboxGroup from "@/components/e-form/edp-checkbox-group.vue";
import dayjs from "dayjs";
import RelevancyWorkModal from "@/components/relevancyWorkModal.vue";

export default {
  name: "taskModal",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    RelevancyWorkModal,
    EdpCheckboxGroup,
    EdpDatePicker,
    EdpTextarea,
    EdpSelect,
    EdpInput,
    IssueMoneyLog,
    CommentList,
    Bizlog,
    AttachmentUpload,
    CreateTaskModal,
    issueWorkTimeLog
  },
  mixins: [catMixin],
  emits: ['reload'],
  data() {
    return {
      dbs: {
        prjIssue: new FieldUpdateDb("prj", "prj_issue")
      },
      comment: "",
      cat: {
        issue_bstatus: [],
        issues_level: [],
        issue_tags: []
      },
      rightTab: "1",
      leftTab: "1",
      visible: false,
      hasDetail: false,
      detail: {
        seqid: ""
      },
      accountList: [],
      prjDetail: {},
      children: [],
      commentList: [],
      stageList: [],
      pre_schedule_start_date: ""
    }
  },
  computed: {
    myIssue() {
      let oid = JSON.parse(localStorage.getItem("company_info")).account_oid
      return this.detail.issue_owner === oid
    },
    hasAddTaskP() {
      return this.$hasP('T_CREATE', this.detail.prjoid)
    },
    hasEditP() {
      if (this.myIssue) {
        return true
      }
      return this.$hasP("T_UPDATEALL", this.detail.prjoid)
    },
    hasDeleteP() {
      if (this.myIssue) {
        return true
      }
      return this.$hasP("T_UPDATEALL", this.detail.prjoid)
    }
  },
  methods: {
    deletePreTask(row) {
      this.$confirm({
        title: `确定移除关联工作项${row.issue_name}?`,
        onOk: async () => {
          const result = this.detail.pretasks.split(",").filter(item => !row.issue_code.split(",").includes(item)).join(",");

          const res = await fetch("post", "/prj/update/onefield", {
            field_id: "prj_issue",
            field_name: "pretasks",
            field_value: result,
            oid: this.detail.oid,
            prjoid: this.detail.prjoid,
            old_value: this.detail.pretasks,
            new_value: result
          })
          if (res.status === 0) {
            this.$message.success("移除成功")
            this.$emit('reload')
            this.getDetail()
          } else {
            this.$message.error(res.message)
          }
        },
        onCancel() {
        },
      });
    },
    openRelevancyWorkModal() {
      this.$refs.relevancyWorkModal.prjoid = this.detail.prjoid
      this.$refs.relevancyWorkModal.open(this.detail)
    },
    changeScheduleStartDate() {
      let val = this.detail.schedule_start_date
      let pre = this.pre_schedule_start_date
      let currentEnd = this.detail.schedule_end_date
      if (val) {
        let formatTemplate = "YYYY/MM/DD"
        if (currentEnd && pre) {
          let start = dayjs(pre, formatTemplate)
          let end = dayjs(currentEnd, formatTemplate)
          let duration = dayjs.duration(end.diff(start))
          currentEnd = dayjs(val, formatTemplate).add(duration.asDays(), "day").format(formatTemplate)
        } else {
          currentEnd = dayjs(val, formatTemplate).add(3, "day").format(formatTemplate)
        }
        this.$refs.schedule_end_date.setValue(currentEnd)
      }
    },
    recordStartDate(status) {
      if (status === true) {
        this.pre_schedule_start_date = this.detail.schedule_start_date
      }
    },
    getStageList() {
      fetch("post", "/prj/phase/retrieve", { prjoid: this.detail.prjoid, page_size: 999 }).then(res => {
        if (res.status === 0) {
          this.stageList = res.data.datas.map(item => {
            return {
              label: item.phase_name,
              value: item.oid,
              key: item.oid
            }
          })
        }
      })
    },
    sendComment() {
      fetch("post", "/prj/issuelog/create", {
        prjoid: this.detail.prjoid,
        issueoid: this.detail.oid,
        log_content: this.comment,
        log_type: "0"
      }).then(res => {
        if (res.status === 0) {
          this.comment = ""
          this.$message.success("评论成功")
          this.$refs.bizlog?.getBizLogList()
          this.$refs.commentList?.getCommentList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    deleteTask(row) {
      this.$confirm({
        title: '确定要删除吗?',
        onOk: () => {
          return fetch("post", "/prj/issue/delete", { oid: row.oid }).then(res => {
            if (res.status === 0) {
              this.$message.success("删除成功")
              this.getChildren()
              return Promise.resolve()
            } else {
              this.$message.error(res.message)
            }
            return Promise.reject()
          })
        },
        onCancel() {
        },
      });
    },
    openChildTask(row) {
      this.$refs.taskModal.open(row.oid)
    },
    openCreateTaskModal() {
      this.$refs.createTaskModal.open({
        route_level: this.detail.route_level - 0 + 1,
        route_map: this.detail.route_map + '/' + this.detail.oid,
        phase_oid: this.detail.phase_oid,
        phase_name: this.detail.phase_name,
        prjoid: this.detail.prjoid,
      })
    },
    getChildren() {
      fetch("post", "/prj/issue/retrieve/children", {
        oid: this.detail.oid
      }).then(res => {
        if (res.status === 0) {
          this.children = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getProjectDetail() {
      fetch("post", "/prj/info", { oid: this.detail.prjoid }).then(res => {
        if (res.status === 0) {
          this.prjDetail = res.data
        }
      })
    },
    getDetail() {
      let url = window.mock ? "http://127.0.0.1:4523/m1/2234408-0-default/prj/issue/details?apifoxApiId=59770876" : "/prj/issue/details"
      fetch("post", url, { oid: this.detail.oid }).then(res => {
        if (res.status === 0) {
          this.detail = {
            ...res.data,
            issue_tag: res.data.issue_tag.split(',')
          }
          this.hasDetail = true
          this.getProjectDetail()
          this.getChildren()
          this.getStageList()
          this.$nextTick(() => {
            this.$refs.moneyLog.getMoneyLogList()
          })
          if (this.$getP(res.data.prjoid).length === 0) {
            this.$store.dispatch("getPrjPermissions")
          }
        } else {
          this.$message.error(res.message)
          this.visible = false
        }
      })
    },
    open(oid) {
      this.detail.oid = oid
      this.visible = true
      this.leftTab = "1"
      this.rightTab = "1"
      this.getDetail()
    }
  }
}
</script>

<style lang="less" scoped>
.modal-title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: #303030;

  > img {
    width: 18px;
    height: 18px;
    margin-right: 7px;
  }
}

/deep/ .ant-modal-title {
  display: flex;
}

.modal-body {
  .body-top {
    padding: 16px 20px;

    .title {
      margin-bottom: 10px;
    }

    .detail {
      display: flex;
      justify-content: flex-start;

      .detail-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        overflow: hidden;

        &:first-child, &:last-child {
          width: 150px;
        }

        &:nth-child(2), &:nth-child(3), &:nth-child(4) {
          width: 130px;
        }

        .round {
          flex-shrink: 0;
          width: 32px;
          height: 32px;
          border-radius: 50%;

          &.blue {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #80b8f9;
            color: white;
            font-weight: 700;
          }
        }

        .right {
          width: calc(100% - 32px - 8px);
          margin-left: 8px;
          overflow: hidden;

          .onefield {
            width: 100%;
          }

          .label {
            color: #909090;
            font-weight: 500;
            font-size: 12px;
            margin-top: 0px;
          }
        }
      }
    }
  }

  .body-content {
    width: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: minmax(0, 1fr) 330px;
    border-top: 1px solid #c2c9cf;
    height: 600px;

    .tag-record-list {
      flex: 1;
      overflow-y: auto;

      .tag-record-item {
        padding: 12px 21px;
        border-bottom: 1px solid #C2C9CF;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 12px;

        &:hover {
          background-color: #f9f9f9;
        }

        > * {
          margin-right: 8px;
        }
      }
    }

    > .left {
      width: 100%;
      border-right: 1px solid #ccd2d7;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .title {
        display: flex;

        .right-action {
          display: flex;
          align-items: center;
          margin-left: auto;
          color: #494949;
          font-size: 14px;
          font-weight: 400;
          cursor: pointer;

          img {
            max-height: 18px;
            max-width: 18px;
            margin-right: 5px;
          }

          i {
            margin-right: 5px;
          }
        }
      }

      .tab-item {
        flex: 1;
        overflow: hidden;
      }

      .active-4 {
        display: flex;
        flex-direction: column;
      }

      .active-2, .active-6, .active-7 {
        display: flex;
        flex-direction: column;

        .children-list {
          .name {
            color: #303030;
            font-weight: 500;
          }

          .edp-status {
            margin-left: auto;
            margin-right: 30px;
            font-size: 14px;
            padding: 0 9px;
          }

          .close {
            margin-right: 0;
            width: 25px;
            height: 25px;
            text-align: center;
            line-height: 25px;
            border-radius: 3px;

            &:hover {
              background-color: #e7e7e7;
            }
          }
        }
      }

      > .ant-tabs {
        flex-shrink: 0;

        /deep/ .ant-tabs-bar {
          border-bottom: 1px solid #C2C9CF;
          margin-bottom: 0;

          .ant-tabs-tab {
            &:first-child {
              margin-left: 15px;
            }

            padding: 12px 9px;
            margin-right: 0;
          }
        }
      }

      .title {
        font-size: 16px;
        font-weight: 500;
        color: #303030;
        margin-top: 25px;

        &:first-child {
          margin-top: 25px;
        }

        margin-bottom: 15px;
        line-height: 1;
        padding: 0 21px;
      }

      .line {
        width: 100%;
        height: 1px;
        background-color: #c2c9cf;
      }

      .form-list {
        margin-bottom: 25px;
        display: grid;
        grid-template-columns:repeat(2, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 10px;
        padding: 0 21px;

        .form-item {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: #4d4d4d;
          min-height: 32px;

          .synergies {
            /deep/ .ant-select-selection {
              height: auto;
            }
          }

          /deep/ .ant-calendar-picker-input {
            color: #4d4d4d;
          }

          /deep/ .ant-select-selection-selected-value {
            font-size: 14px;
          }

          /deep/ .ant-select-search__field {
            font-size: 14px;
          }
        }

        .label {
          width: 110px;
          flex-shrink: 0;
          color: #303030;
          font-size: 14px;
        }
      }
    }

    .right {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .tab {
        display: flex;
        width: 100%;
        flex: 1;
        flex-direction: column;
        overflow: hidden;

        .y-scroll {
          flex: 1;
          overflow-y: auto;

          .active-1 {
          }
        }
      }

      > .title {
        height: 45px;
        line-height: 50px;
        padding: 0 13px;
        font-size: 16px;
        font-weight: 500;
        color: #303030;
      }

      > .tab {
        padding: 0 13px;

        .ant-tabs {
          /deep/ .ant-tabs-bar {
            margin-bottom: 0;
          }
        }
      }

      .comment-input {
        margin-top: auto;
        display: flex;
        height: 50px;
        align-items: center;
        border-top: 1px solid #CACACA;

        input {
          flex: 1;
          height: 100%;
          border: none;
          outline: none;
          padding-left: 10px;
          font-size: 14px;
          color: #909090;
        }

        .send {
          padding: 0 20px;
          cursor: pointer;
          color: #333;
        }
      }
    }
  }
}
</style>
