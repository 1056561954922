<template>
  <a-modal v-model="visible" title="添加工时信息" width="680px" class="createWorkTimeModal">
    <a-form-model layout="vertical" ref="form" :model="form" :rules="rules" class="edp-form">
      <div class="line-2">
        <a-form-model-item label="项目" prop="prjoid">
          <a-select v-if="form.prjoid !== '2300000001'" :disabled="disableIssue" v-model="form.prjoid" @change="onChangePrj" show-search option-filter-prop="children">
            <a-select-option v-for="item in projectList" :key="item.oid" :value="item.oid" :options="item">
              {{item.project_name}}
            </a-select-option>
          </a-select>
          <a-select v-else value="一般事项" disabled></a-select>
        </a-form-model-item>
        <a-form-model-item label="工作项" prop="issueoid">
          <a-select :disabled="!form.prjoid || disableIssue" v-model="form.issueoid" @change="onChangeIssue">
            <a-select-option v-for="item in issueList" :key="item.oid" :value="item.oid" :options="item">
              {{item.issue_name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="成员" prop="issue_owner">
          <a-select showSearch v-model="form.issue_owner" :disabled="!isAdmin || disableIssue">
            <a-select-option v-for="item in accountList" :value="item.user_oid" :key="item.user_oid">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="剩余工时">
          <a-input :disabled="true" v-model="form.worktime_left" addon-after="小时"></a-input>
        </a-form-model-item>
        <a-form-model-item label="开始时间" prop="start_time">
          <a-date-picker valueFormat="YYYY/MM/DD HH:mm" format="YYYY/MM/DD HH:mm" :show-time="{ format: 'HH:mm' }" v-model="form.start_time" style="width: 100%;"/>
        </a-form-model-item>
        <a-form-model-item label="实际投入时长" prop="worktime_min">
          <a-input v-model="form.worktime_min" addon-after="小时"></a-input>
        </a-form-model-item>
      </div>
      <a-form-model-item label="描述" prop="phase_desc">
        <a-textarea :rows="4" v-model="form.remark"></a-textarea>
      </a-form-model-item>
    </a-form-model>
    <attachment-upload :source-oid="form.oid" source-type="8" @setOid="oid => form.oid = oid">
      <template slot="title">
        附件上传
      </template>
    </attachment-upload>
    <template #footer>
      <div class="modal-footer">
        <a-checkbox v-model="createNext" :disabled="type !== 'create'">
          继续创建下一个
        </a-checkbox>
        <a-space>
          <a-button @click="visible = false">
            取消
          </a-button>
          <a-button type="primary" @click="submit">
            确定
          </a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
</template>

<script>
import attachmentUpload from "@/components/attachmentUpload.vue";
import autoInput from "@/components/autoInput.vue";
import {fetch} from "@/utils/request";
import dayjs from "dayjs";
export default {
  name: "createStageModal",
  props: {
    prjOid: {
      type: String
    },
    issueOid: {
      type: String
    }
  },
  data() {
    return {
      form: {
        oid: '',
        prjoid: '',
        issueoid: '',
        issue_owner: '',
        start_time: '',
        worktime_min: '',
        remark: '',
        worktime_left: ''
      },
      rules: {
        prjoid: [
          {required: true, message: "请填选择项目", trigger: 'blur'},
        ],
        issueoid: [
          {required: true, message: "请填选择工作项", trigger: 'blur'},
        ],
        issue_owner: [
          {required: true, message: "请填选择成员", trigger: 'blur'},
        ],
        start_time: [
          {required: true, message: "请填选择开始时间", trigger: 'change'},
        ],
        worktime_min: [
          {required: true, message: "请填填写实际投入时长", trigger: 'blur'},
        ]
      },
      visible: false,
      backup: {},
      preFill: {},
      accountList: [],
      projectList: [],
      issueList: [],
      isAdmin: false,
      company_info: {},
      createNext: false,
      type: 'create',
      disableIssue: false
    }
  },
  // eslint-disable-next-line vue/no-unused-components
  components: {attachmentUpload, autoInput},
  created() {
    this.backup = JSON.parse(JSON.stringify(this.form))
    this.company_info = JSON.parse(localStorage.getItem("company_info"))
  },
  methods: {
    open(type, fill = {}) {
      this.getProjectList()
      console.log(fill);
      this.type = type
      this.disableIssue = false
      this.form = {
        ...this.backup,
        ...fill
      }
      this.preFill = { ...fill }
      if (this.type === 'update'){ // 编辑更新
        this.disableIssue = true
        this.onChangePrj('update', {data: {attrs: {options: fill}}})
      } else {
        this.form.start_time = dayjs().format('YYYY/MM/DD HH:mm')
        this.form.prjoid = this.prjOid
        this.form.issueoid = this.issueOid
        if (this.form.prjoid) {
          this.disableIssue = true
          this.getAccount()
          this.getWorkList()
        }
      }
      this.createNext = false
      this.visible = true
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          console.log(valid, this.form);
          let url = this.type === 'create' ? '/prj/worktimelog/create' : '/prj/worktimelog/update'
          fetch('post',url, {
            ...this.form,
            worktime_min: this.form.worktime_min * 60
          }).then((res)=>{
            if (res.status === 0) {
              this.$message.success(`${this.type === 'create' ? '添加成功！' : '编辑成功！'}`)
              this.$emit('reload')
              if (this.createNext) {
                this.open('create', this.preFill)
                this.createNext = true
              } else {
                this.visible = false
              }
            } else {
              this.$message.error(res.message)
            }
          })
        }
      });
    },
    getAccount() {
      fetch("post", "/prj/userlist/list", { prjoid: this.form.prjoid }).then(res => {
        if (res.status === 0) {
          this.accountList = res.data
          if (this.accountList.find(item => item.user_oid === this.company_info.account_oid)) {
            this.form.issue_owner = this.company_info.account_oid
          }
        }
      })
    },
    getProjectList() {
      fetch("post", "/prj/retrieve/my").then(res => {
        if (res.status === 0) {
          this.projectList = res.data
        }
      })
    },
    getWorkList() {
      fetch("post", "/prj/issue/retrieve", {
        prjoid: this.form.prjoid,
        page_size: 999,
        issue_type: "T"
      }).then(res => {
        if (res.status === 0) {
          this.issueList = res.data.datas
          if (this.form.issueoid) {
            console.log(this.issueList.filter(e => e.oid === this.form.issueoid));
            let filter = this.issueList.filter(e => e.oid === this.form.issueoid)
            this.form.worktime_left = filter[0]?.worktime_left
          }
        }else {
          this.$message.error(res.message)
        }
      })
    },
    onChangePrj(value, option) {
      let item = option.data.attrs.options
      console.log(item.creator_oid, this.company_info.account_oid);
      this.isAdmin = item.creator_oid === this.company_info.account_oid // 判断当前登录人选择的项目是否管理员
      if (!this.form.prjoid) return;
      if (value !== 'update') {
        this.form.issueoid = ""
        this.form.issue_owner = ""
      }
      this.getAccount()
      this.getWorkList()
    },
    onChangeIssue(value, option) {
      let item = option.data.attrs.options
      this.form.worktime_left = item.worktime_left
      console.log(item);
    }
  }
}
</script>

<style lang="less" scoped>
  .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
