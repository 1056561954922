<template>
  <div class="workTimeLog" :style="`height: ${paddingX !== '0' ? '399px' : 'auto'}`">
    <div class="head" :style="`padding: 21px ${paddingX}`">
      <div class="title" :style="`font-weight: ${paddingX !== '0' ? '500' : '400'}`">工时</div>
      <div class="right-action" @click="openModal('create')">
        <img :src="$toUrl('workinghours.png')" alt="">
        登记工时
      </div>
    </div>
    <div class="main" :style="`height: ${paddingX !== '0' ? '336px' : 'auto'}`">
      <template v-for="item in list">
        <div class="work-item" :key="item.oid" :style="`padding: 0 ${paddingX}`">
          <div class="left">
            <div class="name">
              {{ item.issue_admin_title ? item.issue_admin_name + '-' + item.issue_admin_title : item.issue_admin_name }}
            </div>
            <div class="time">{{ item.start_time }} 开始</div>
          </div>
          <div class="right">
            <div class="wrok-time">{{ item.worktime_min }}小时</div>
            <div class="wrok-icon">
              <div class="action edit" @click="openModal('update', item)">
                <a-icon type="form" />
              </div>
              <div class="action delete" @click="deleteWorkTimeLog(item)">
                <a-icon type="delete" />
              </div>
            </div>
          </div>
        </div>
        <div class="line" v-if="paddingX !== '0'" :key="item.oid"></div>
      </template>
    </div>
    <div v-if="list.length === 0">
      <edp-empty></edp-empty>
    </div>
    <create-work-time-modal ref="workTimeModal" @reload="$emit('reload')" :prj-oid="prjOid"
                            :issue-oid="issueOid"></create-work-time-modal>
  </div>
</template>

<script>
import { fetch } from "@/utils/request";
import createWorkTimeModal from "@/components/createWorkTimeModal.vue";

export default {
  name: "issueWorkTimeLog",
  props: {
    list: {
      type: Array
    },
    paddingX: {
      type: String,
      default: () => '21px'
    },
    prjOid: {
      type: String
    },
    issueOid: {
      type: String
    }
  },
  components: { createWorkTimeModal },
  data() {
    return {}
  },
  created() {
  },
  methods: {
    openModal(type, item) {
      this.$refs.workTimeModal.open(type, item)
    },
    deleteWorkTimeLog(row) {
      this.$confirm({
        title: '确定要删除吗?',
        onOk: () => {
          return fetch("post", "/prj/worktimelog/delete", { oid: row.oid }).then(res => {
            if (res.status === 0) {
              this.$message.success("删除成功")
              this.$emit('reload')
            } else {
              this.$message.error(res.message)
            }
          })
        },
        onCancel() {
        },
      });
    }
  }
}
</script>

<style lang="less" scoped>
.workTimeLog {
  overflow: hidden;

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 21px 0;

    .title {
      font-size: 16px;
      display: flex;
      align-items: center;
    }

    .right-action {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-left: auto;
      cursor: pointer;

      img {
        width: 17px;
        height: 17px;
        display: block;
        margin-right: 5px;
      }
    }
  }

  .main {
    overflow-y: auto;

    .work-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;

      .left {
        display: flex;
        align-items: center;
        font-size: 16px;

        .name {
          line-height: 16px;
          color: #4D4D4D;
          padding: 5px 10px;
          border-radius: 2px 2px 2px 2px;
          background: #eeeeee;
          font-weight: 500;
          margin-right: 21px;
        }

        .time {
          line-height: 16px;
          color: #515151;
        }
      }

      .right {
        font-size: 16px;

        .wrok-time {
          font-weight: 500;
          color: #515151;
          line-height: 16px;
        }

        .wrok-icon {
          display: none;

          .action {
            display: inline-block;
            width: 24px;
            height: 24px;
            text-align: center;
            cursor: pointer;
          }

          .edit {
            margin-right: 10px;
          }
        }
      }

      &:hover {
        .wrok-time {
          display: none;
        }

        .wrok-icon {
          display: block;
        }
      }
    }

    .line {
      width: 100%;
      height: 1px;
      background-color: #C2C9CF;
      margin-bottom: 12px;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }
}
</style>
