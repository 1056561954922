<template>
  <a-checkbox-group
    class="edp-checkbox-group"
    :options="options"
    v-model="tempValue"
    @change="changeEvent"
  ></a-checkbox-group>
</template>

<script>
import edpFormMixins from "@/components/e-form/edpFormMixins";

export default {
  name: "edp-checkbox-group",
  mixins: [edpFormMixins],
  props: {
    options: {
      type: Array,
      required: true
    }
  },
  methods: {
    getValue(val) {
      if (typeof val === 'string' && val.length > 0) {
        return val.split(",")
      }
      if (Array.isArray(val)) {
        return val
      }
      return []
    },
    getLabel(value) {
      return value.map(item => {
        if (this.options.length && typeof this.options.length[0] === 'object') {
          return this.options.find(option => option.value === item)?.value ?? item
        }
        return item
      }).join(",")
    },
    getSetBeforeValue(tempValue) {
      return tempValue.join(",")
    }
  }
}
</script>

<style lang="less" scoped>
.edp-checkbox-group {
  /deep/ .ant-checkbox-group-item {
    span {
      font-size: 12px;
      color: #303030;
    }
  }
}
</style>