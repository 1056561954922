<template>
  <div class="comment-list">
    <div class="comment-top">
      <div class="comment-pagination"><a-pagination v-model="page.page_index" size="small" :showLessItems="true" :pageSize="5" :total="page.total" @change="onChange"/></div>
      <div class="comment-num">共{{page.total_page_count}}页 {{page.total}}条</div>
    </div>
    <div v-if="commentList.length === 0" style="margin-top: 20px">
      <edp-empty></edp-empty>
    </div>
    <div class="comment-item" v-for="(item) in commentList" :key="item.oid">
      <img class="picture" :src="item.pic" alt="">
      <div class="comment-right">
        <div class="info">
          <div class="name">{{ item.creator_title ? item.creator_name + '-' + item.creator_title : item.creator_name}}: <span class="time">{{ item.create_time }}</span></div>
        </div>
        <div class="content">{{item.log_content}}</div>
        <div class="more">
          <div class="action" v-if="edit">
            <div class="item" @click="openEditModal(item)">编辑</div>
            <div class="item" @click="deleteComment(item)">删除</div>
          </div>
        </div>
      </div>
    </div>
    <a-modal title="编辑评论" v-model="editModal.visible" :bodyStyle="{paddingTop: '15px'}" @ok="saveComment" :confirm-loading="editModal.loading">
      <div style="font-weight: 500;color: #3B3B3B; margin-bottom: 5px;font-size: 14px">评论内容：</div>
      <a-textarea ref="editModalValue" v-model="editModal.form.log_content"></a-textarea>
    </a-modal>
  </div>
</template>

<script>
import {fetch} from "@/utils/request.js";

export default {
  emits: ['reload'],
  props: {
    issueoid: {
      type: String
    },
    prjoid: {
      type: String
    },
    edit: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      editModal: {
        loading: false,
        visible: false,
        form: {
          log_content: ""
        }
      },
      commentList: [],
      page: {
        page_size: 5,
        page_index: 1,
        total: 0,
      }
    }
  },
  watch: {
    issueoid: {
      immediate: true,
      deep: true,
      handler() {
        this.getCommentList()
      }
    }
  },
  mounted() {

  },
  methods: {
    deleteComment(row) {
      this.$confirm({
        title: '确定要删除吗?',
        onOk: () => {
          return fetch("post", "/prj/issuelog/delete", {oid: row.oid}).then(res => {
            if (res.status === 0) {
              this.$message.success("删除成功")
              this.getCommentList()
            } else {
              this.$message.error(res.message)
            }
          })
        },
        onCancel() {},
      });
    },
    openEditModal(row) {
      this.editModal.visible = true
      this.editModal.loading = false
      this.editModal.form = {
        oid: row.oid,
        log_content: row.log_content
      }
      this.$nextTick(() => {
        this.$refs.editModalValue.focus()
      })
    },
    saveComment() {
      if (!this.editModal.form.log_content) {
        this.$message.error("请输入评论内容")
        return
      }
      this.editModal.loading = true
      fetch("post", "/prj/issuelog/update", {
        ...this.editModal.form
      }).then(res => {
        if (res.status === 0) {
          this.$message.success("修改成功")
          this.editModal.visible = false
          this.getCommentList()
          return Promise.resolve()
        } else {
          this.$message.error(res.message)
          return Promise.reject()
        }
      }).finally(() => {
        this.editModal.loading = false
      })
    },
    getCommentList() {
      fetch("post", "/prj/issuelog/retrieve", {
        prjoid: this.prjoid,
        issueoid: this.issueoid,
        page_size: this.page.page_size,
        page_index: this.page.page_index
      }).then(res => {
        if (res.status === 0) {
          this.commentList = res.data.datas
          this.page = res.data.page
        }
      })
    },
    onChange(current) {
      console.log(current);
      this.page.page_index = current
      this.getCommentList()
    }
  }
}
</script>

<style lang="less" scoped>
.comment-list {
  .comment-top{
    padding-left: 16px;
    height: 55px;
    line-height: 55px;
    box-sizing: border-box;
    border-bottom: 1px solid #EDEDED;
    font-size: 14px;
    color: #000000;
    display: flex;
    align-items: center;

    /deep/ .ant-pagination{
      margin-right: 10px;

      .ant-pagination-prev, .ant-pagination-next{
        border: 1px solid #909090;

        &.ant-pagination-prev{
          margin-right: 10px;
        }

        a{
          display: flex;
          align-items: center;
          justify-content: center;
          color: #909090;
        }

      }

      .ant-pagination-item{
        border: 1px solid #909090;
        margin-right: 10px;

        &.ant-pagination-item-active{
          border-color: #ff6700;
        }

        &:hover{
          border-color: #ff6700;
        }
      }
    }
  }
  .comment-item{
    padding: 13px;
    font-size: 12px;
    display: flex;
    font-weight: 400;
    >img{
      width: 19px;
      height: 19px;
      vertical-align: middle;
      border-radius: 50%;
    }
    .comment-right {
      margin-left: 8px;
      .info {
        line-height: 1.8;
        .name {
          font-weight: 500;
          color: #333;
          margin-right: 5px;
          display: inline;
          .time {
            color: #919191;

          }
        }
        .content {
          display: inline;
        }
      }
      .content {
        color: #000;
      }
    }
    .more {
      margin-top: 5px;
      color: #919191;
      display: flex;
      .action {
        display: flex;
        visibility: hidden;
        .item {
          margin-right: 6px;
          padding-right: 6px;
          position: relative;
          cursor: pointer;
          &:hover {
            color: #333;
          }
          &:not(:last-child)::after {
            content: " ";
            display: block;
            position: absolute;
            height: 80%;
            width: 1px;
            right: 0;
            top: 10%;
            background-color: #d3d3d3;
          }
        }
      }
    }
    &:hover {
      .more .action {
        visibility: visible;
      }
    }
  }
}
</style>
