<template>
  <div class="money-log">
    <div class="title" :style="`padding: 0 ${paddingX}`" v-if="!(disabled && !title)">
      <div class="text">
        {{title}}
      </div>
      <div
        v-if="!disabled"
        class="right-action"
        @click="openModal('create', {
          issueoid: oid
        })"
      >
        <img :src="$toUrl('createMoneyLog.png')" alt="">
        登记财务
      </div>
    </div>
    <div style="padding-top: 30px" v-if="list.length === 0">
      <edp-empty></edp-empty>
    </div>
    <div class="log-list">
      <div class="log-item" v-for="item in list" :key="item.oid" :style="`--paddingX:${paddingX};`">
        <div class="header">
          <div class="user-info">
            <span v-if="item.money_status === '0'" class="money-type red">支</span>
            <span v-if="item.money_status === '1'" class="money-type green">收</span>
            <span class="username">{{item.reviser_title ? item.reviser_name + "-" + item.reviser_title : item.reviser_name}}</span>
          </div>
          <div class="date-time">{{item.business_date_format}}</div>
          <div
            class="amount"
            :class="{
              red: item.money_status === '0',
              green: item.money_status === '1',
            }"
          >
            <div class="money-type">{{item.money_type}}</div>
            {{item.amount}}
          </div>
        </div>
        <div class="content">
          <div class="left">
            <div class="description" v-if="item.remark">{{item.remark}}</div>
            <template v-if="item.money_tag">
              <div class="tag" v-for="(text, i) in item.money_tag.split(',')" :key="i">{{text}}</div>
            </template>
          </div>
          <div class="right">
            <div v-if="!disabled" class="action edit" @click="openModal('update', item)"><a-icon type="form" /></div>
            <div v-if="!disabled" class="action delete" @click="deleteMoneyLog(item)"><a-icon type="delete" /></div>
          </div>
        </div>
        <div class="files">
          <a v-for="file in item.attachments" :key="file.oid" class="file-item" :href="$toUrl(file.file_path)" target="_blank">
            <a-icon type="link" />
            <span class="file-name">{{ file.file_name }}{{file.file_type}}</span>
          </a>
        </div>
      </div>
    </div>
    <money-log-modal :prj-oid="prjOid" ref="modal" @reload="reload"></money-log-modal>
  </div>
</template>

<script>
import {fetch} from "@/utils/request.js";
import MoneyLogModal from "@/components/moneyLogModal.vue";
import dayjs from "dayjs";

export default {
  name: "issueMoneyLog",
  components: {MoneyLogModal},
  emits: ["reload"],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: () => "财务"
    },
    prjOid: {
      type: String,
    },
    oid: {
      type: String
    },
    name: {
      type: String
    },
    paddingX: {
      type: String,
      default: () => '21px'
    }
  },
  data() {
    return {
      list: []
    }
  },
  created() {
  },
  methods: {
    reloadModalIssueList() {
      this.$refs.modal.getIssueList()
    },
    deleteMoneyLog(row) {
      this.$confirm({
        title: '确定要删除吗?',
        onOk: () => {
          return fetch("post", "/prj/moneylog/delete", {oid: row.oid}).then(res => {
            if (res.status === 0) {
              this.$message.success("删除成功")
              this.$emit('reload')
              this.getMoneyLogList()
            } else {
              this.$message.error(res.message)
            }
          })
        },
        onCancel() {},
      });
    },
    reload() {
      this.getMoneyLogList()
      this.$emit("reload")
    },
    openModal(type, fill) {
      this.$refs.modal.open(type, fill)
      this.$refs.modal.disableIssue = true
    },
    getMoneyLogList() {
      if (!this.prjOid || !this.oid) {
        return
      }
      let url = window.mock ? "http://127.0.0.1:4523/m1/2234408-0-default/prj/moneylog/list" : "/prj/moneylog/list"
      fetch("post", url, {
        prjoid: this.prjOid,
        issueoid: this.oid,
      }).then(res => {
        if (res.status === 0 || window.mock) {
          this.list = res.data.map(item => {
            return {
              ...item,
              business_date_format: dayjs(item.business_date, "YYYY/MM/DD HH:mm:ss").format("MM月DD日 HH:mm")
            }
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.money-log {
  margin-bottom: 20px;

  .title {
    display: flex;
    align-items: center;
    padding: 0 21px;

    .text {
      font-weight: 500;
      font-size: 16px;
      color: #303030;
    }
    .right-action {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-left: auto;
      cursor: pointer;
      img {
        margin-right: 5px;
      }
    }
  }
  .log-list {
    margin-top: 15px;
    .log-item {
      border-top: 1px solid #eee;
      padding: 20px var(--paddingX, 0);
      &:last-child {
        border-bottom: 1px solid #eee;
      }
      &:hover .content .right {
        visibility: visible;
      }
      .header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .user-info {
          display: flex;
          align-items: center;
          margin-right: 10px;
          .money-type {
            border-radius: 50%;
            color: white;
            width: 25px;
            height: 25px;
            text-align: center;
            line-height: 25px;
            margin-right: 5px;
            font-size: 14px;
            &.green {
              background-color: green;
            }
            &.red {
              background-color: red;
            }
          }
          .username {
            font-size: 16px;
            color: #3B3B3B;
          }
        }
        .date-time {
          color: #909090;
        }
        .amount {
          margin-left: auto;
          font-weight: 700;
          font-size: 16px;
          &.green {
            color: green;
          }
          &.red {
            color: red;
          }
          .money-type {
            display: inline;
            margin-right: 3px;
          }
        }
      }
      .content {
        display: flex;
        .left {
          flex: 1;
          line-height: 25px;
          .description {
            margin-right: 10px;
            color: #3B3B3B;
            font-size: 16px;
          }
          .tag {
            margin-right: 10px;
            background-color: #eee;
            padding: 2px 8px;
            font-size: 14px;
            &:last-child {
              margin-right: 0;
            }
          }
          .description, .tag {
            display: inline;
          }
        }
        .right {
          visibility: hidden;
          margin-left: 15px;
          flex-shrink: 0;
          .action {
            display: inline-block;
            width: 24px;
            height: 24px;
            text-align: center;
            cursor: pointer;
            &:hover {
              color: #36A3FF;
            }
            &:not(:last-child) {
              margin-right: 10px;
            }
          }
        }
      }
      .files {
        line-height: 25px;
        margin-top: 5px;
        .file-item {
          margin-right: 10px;
          font-size: 14px;
          .file-name {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
</style>
