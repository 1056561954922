<template>
  <a-modal v-model="visible" :title="(type === 'create' ? '新增' : '编辑') + '财务明细'" width="680px"
           class="createTaskModal">
    <a-form-model layout="vertical" ref="form" :model="form" class="edp-form">
      <div class="line-2">
        <a-form-model-item>
          <span slot="label">工作项<i style="color:red">*</i></span>
          <a-select v-model="form.issueoid" :disabled="disableIssue">
            <a-select-option v-for="item in issueList" :key="item.oid" :value="item.oid">
              {{item.issue_name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <span slot="label">创建人<i style="color:red">*</i></span>
          <div style="height: 32px;line-height: 32px">{{form.reviser_title ? form.creator_name + "-" + form.reviser_title : form.creator_name}}</div>
        </a-form-model-item>
      </div>
      <div class="line-2">
        <a-form-model-item>
          <span slot="label">类型<i style="color:red">*</i></span>
          <a-radio-group v-model="form.money_status">
            <a-radio value="0">支出</a-radio>
            <a-radio value="1">收入</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item :label="form.money_status === '0' ? '付款时间' : '收款时间'">
          <a-date-picker
            style="width: 100%;"
            format="YYYY-MM-DD HH:mm"
            valueFormat="YYYY-MM-DD HH:mm"
            v-model="form.business_date"
            :showTime="{
              format: 'HH:mm'
            }"
          />
        </a-form-model-item>
        <a-form-model-item>
          <span slot="label">金额<i style="color:red">*</i></span>
          <a-input-group compact>
            <a-select style="width: 30%" v-model="form.money_type">
              <a-select-option v-for="item in cat.money_rate" :key="item.oid" :value="item.cat_code">
                {{ item.cat_code }}
              </a-select-option>
            </a-select>
            <a-input style="width: 70%;" v-model="form.amount" />
          </a-input-group>
        </a-form-model-item>
      </div>
      <a-form-model-item label="财务标签">
        <a-checkbox-group
            v-model="form.money_tag"
            :options="cat.money_tags.map(item => item.cat_name)"
        />
      </a-form-model-item>
      <a-form-model-item label="描述">
        <a-textarea style="height: 80px" v-model="form.remark"></a-textarea>
      </a-form-model-item>
    </a-form-model>
    <attachment-upload :sourceOid="form.oid" source-type="6" :prj-oid="prjOid" @setOid="oid => form.oid = oid" style="margin-top: 30px;" title="附件上传"></attachment-upload>
    <template #footer>
      <div class="modal-footer">
        <a-checkbox v-model="createNext" v-if="type === 'create'">
          继续创建下一个
        </a-checkbox>
        <div v-else></div>
        <a-space>
          <a-button @click="visible = false">
            取消
          </a-button>
          <a-button type="primary" @click="save">
            确定
          </a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
</template>

<script>
import AttachmentUpload from "@/components/attachmentUpload.vue";
import {fetch} from "@/utils/request.js";
import catMixin from "@/utils/mixins/catMixin.js";
import dayjs from "dayjs";

export default {
  name: "moneyLogModal",
  components: { AttachmentUpload },
  mixins: [catMixin],
  emits: ['reload'],
  props: {
    prjOid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      type: "create",
      createNext: false,
      disableIssue: false,
      cat: {
        issues_level: [],
        money_tags: [],
        money_rate: []
      },
      visible: false,
      form: {
        prjoid: "",
        reviser_title:"",
        issue_name: "",
        creator_name: "",
        issueoid: "",
        money_tag: "",
        amount: "",
        money_status: "",
        remark: "",
        money_type: "CNY",
        business_date: "",
        oid: ""
      },
      accountList: [],
      demandList: [],
      issueList: [],
      backups: {},
      preFill: {}
    }
  },
  created() {
    this.backups = JSON.parse(JSON.stringify(this.form))
  },
  methods: {
    getIssueList() {
      this.issueList = []
      fetch("post", "prj/issue/retrieve", {
        prjoid: this.prjOid,
        issue_type: "T",
        page_size: 999
      }).then(res => {
        if (res.status === 0) {
          this.issueList = res.data.datas
        }
      })
    },
    save() {
      if (!this.form.issueoid) {
        this.$message.error("请填写工作项")
        return
      }
      if (!this.form.money_status) {
        this.$message.error("请选择类型")
        return
      }
      if (!this.form.amount) {
        this.$message.error("请填写金额")
        return
      }
      let url = "/prj/moneylog/create"
      if (this.type === 'update') {
        url = '/prj/moneylog/update'
      }
      delete this.form.attachments
      fetch("post", url, {
        ...this.form,
        money_tag: this.form.money_tag.join(',')
      }).then(res => {
        if (res.status === 0) {
          this.$message.success("新增成功")
          this.$emit("reload")
          if (this.createNext) {
            this.open('create', this.preFill)
            this.createNext = true
          } else {
            this.visible = false
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    open(type, fill) {
      this.getIssueList()
      console.log(fill)
      this.type = type
      let form = {
        ...this.backups
      }
      this.preFill = { ...fill }
      if (type === 'create') {
        let companyInfo = JSON.parse(localStorage.getItem("company_info"))
        console.log(companyInfo)
        form.creator_oid = companyInfo.account_oid
        form.creator_name = companyInfo.name
        form.reviser_title = companyInfo.title
        form.prjoid = this.prjOid
        form.business_date = dayjs().format("YYYY-MM-DD HH:mm")
        form.money_status = "0"
        if (typeof fill === "object") {
          Object.assign(form, fill)
        }
        if (this.cat.money_tags?.length) {
          form.money_tag = [this.cat.money_tags[0].cat_name]
        }
      } else {
        console.log(form)
        Object.assign(form, fill)
      }
      this.disableIssue = false
      this.form = {
        ...form,
        money_tag: Array.isArray(form.money_tag) ? form.money_tag : form.money_tag.split(',')
      }
      this.createNext = false
      this.visible = true
    }
  }
}
</script>

<style lang="less" scoped>
.ant-form-vertical .ant-form-item {
  padding-bottom: 0;
}

.ant-form-item {
  margin-bottom: 20px;

  /deep/ .ant-form-item-control-wrapper {
    .ant-form-item-children {
      .ant-radio + span {
        color: #303030;
        font-weight: 500;
      }

      .ant-checkbox + span {
        color: #303030;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}

/deep/ .edp-form .ant-form-item-label label {
  font-weight: 500;
  font-size: 16px;
  color: #303030;
}

.line {
  width: 100%;
  height: 23px;
  background: #F8F8F8;
}

/deep/ .ant-table .ant-btn {
  padding: 0;
}

.createTaskModal {
  /deep/ .ant-modal-body {
    height: 500px;
    overflow-y: auto;
  }

  .label {
    font-size: 16px;
    color: #303030;
    margin-bottom: 10px;
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.relation {
  margin-bottom: 30px;

  .relation-head {
    height: 40px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #CACACA;

    .relation-name {
      font-size: 14px;
      color: #303030;
    }

    .relation-action {
      margin-left: auto;
      font-size: 14px;
      display: flex;
      font-weight: 500;
      align-items: center;
      cursor: pointer;

      .anticon {
        margin-right: 5px;
        font-size: 20px;
      }
    }
  }

  .relation-td {
    display: flex;
    align-items: center;

    > img {
      height: 16px;
      margin-right: 5px;
    }
  }
}
</style>
